import { Injectable } from '@angular/core';
import { type ServiceToolsModel } from '@big-direkt/utils/shared';
import { type CoPaymentCalculationTaxonomiesByYear } from '../models/co-payment-calculation-taxonomies-by-year.model';
import { type CoPaymentTaxonomyNamesGerman, type CoPaymentCalculationTaxonomies } from '../models/co-payment-calculation-taxonomies.model';
import { convertDefault } from '../util';

@Injectable({
    providedIn: 'root',
})
export class CoPaymentTaxonomyMapper {
    private readonly taxonomyNameTranslations: Record<CoPaymentTaxonomyNamesGerman, keyof CoPaymentCalculationTaxonomies> = {
        arbeitslosenGrundbetrag: 'unemploymentBenefitsBaseAmount',
        ehegattenFreibetrag: 'spouseExemption',
        kinderFreibetrag: 'childrenExemption',
    };

    public mapTaxonomies(taxonomies: ServiceToolsModel[]): CoPaymentCalculationTaxonomiesByYear {
        const result: CoPaymentCalculationTaxonomiesByYear = new Map();

        const filteredNames: CoPaymentTaxonomyNamesGerman[] = ['arbeitslosenGrundbetrag', 'ehegattenFreibetrag', 'kinderFreibetrag'];

        taxonomies.forEach(taxonomy => {
            const regex = new RegExp(`^(?<year>\\d{4})_(?<name>${filteredNames.join('|')})$`, 'i');
            const match = regex.exec(taxonomy.name);

            if (match?.groups) {
                const year = parseInt(match.groups.year, 10);
                const { name } = match.groups;

                const mappedName = this.translateName(name as CoPaymentTaxonomyNamesGerman);

                let taxonomyGroup = result.get(year);

                if (!taxonomyGroup) {
                    taxonomyGroup = {};
                    result.set(year, taxonomyGroup);
                }

                taxonomyGroup[mappedName] = convertDefault(taxonomy.replacement);
            }
        });

        return result;
    }

    private translateName(germanName: CoPaymentTaxonomyNamesGerman): keyof CoPaymentCalculationTaxonomies {
        return this.taxonomyNameTranslations[germanName];
    }
}
