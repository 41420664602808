import { CurrencyPipe, PercentPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject, reflectComponentType, type AfterViewInit } from '@angular/core';
import { UiButtonComponent } from '@big-direkt/ui/button';
import { ScrollService } from '@big-direkt/utils/environment';
import { TranslocoDirective } from '@jsverse/transloco';
import { CoPaymentCalculationResultModel } from '../models/co-payment-calculation-result.model';

@Component({
    selector: 'big-service-tool-co-payment-calculator-result',
    standalone: true,
    imports: [CurrencyPipe, PercentPipe, TranslocoDirective, UiButtonComponent],
    templateUrl: './co-payment-calculator-result.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoPaymentCalculatorResultComponent implements AfterViewInit {
    public readonly percentFormat = '1.2-2';
    private readonly scrollService = inject(ScrollService);

    @Input() public readonly calculationResult!: CoPaymentCalculationResultModel;

    @Output() public readonly backClicked = new EventEmitter<void>();

    public onBackClick(): void {
        this.backClicked.emit();
    }

    public ngAfterViewInit(): void {
        const selector = reflectComponentType(CoPaymentCalculatorResultComponent)?.selector;
        this.scrollService.scroll(selector, { scrollIntoView: true });
    }
}

export default CoPaymentCalculatorResultComponent;
