@if (form) {
    <ng-container *transloco="let t; prefix: 'stCoPaymentCalculator'">
        @if (isLoading) {
            <big-ui-spinner />
        }
        @if (yearOptions$ | async; as yearOptions) {
            <form
                [formGroup]="form"
                (ngSubmit)="submit($event)"
                [ngClass]="{
                    'hidden': calculationResult
                }">
                <big-ui-form-row
                    #yearFormRow
                    [id]="'year'"
                    [label]="t('label.year')"
                    [isRequired]="true"
                    [errors]="form.controls.year.errors"
                    [showError]="hasBeenSubmitted">
                    <big-ui-select
                        [id]="'year'"
                        [isValid]="form.controls.year.valid"
                        [disableTransloco]="true"
                        [options]="yearOptions"
                        [showValidation]="hasBeenSubmitted"
                        [isRequired]="true"
                        formControlName="year"
                        name="year"
                        [bigStFieldTracking]="{
                            metadata,
                            fieldName: yearFormRow.label!,
                            valid: !hasBeenSubmitted || form.controls.year.valid
                        }" />
                </big-ui-form-row>
                <big-ui-form-row
                    #receivesSocialWelfareFormRow
                    [id]="'receivesSocialWelfare'"
                    [label]="t('label.receivesSocialWelfare')"
                    [isRequired]="true"
                    [errors]="form.controls.receivesSocialWelfare.errors"
                    [showError]="hasBeenSubmitted"
                    radiogroupId="receivesSocialWelfareRadiogroup"
                    [infoTextTemplate]="socialWelfareInfo"
                    (infoLayerOpened)="trackStInfoIconEvent(receivesSocialWelfareFormRow.label!)">
                    <big-ui-button-group
                        class="w-full"
                        buttonRowClasses="w-full md:w-6/12 lg:w-4/12"
                        [isValid]="form.controls.receivesSocialWelfare.valid"
                        [isTouched]="form.controls.receivesSocialWelfare.touched"
                        [options]="socialWelfareOptions"
                        [showValidation]="hasBeenSubmitted"
                        (ngModelChange)="onReceivesSocialWelfareChange()"
                        [isRequired]="true"
                        radiogroupId="receivesSocialWelfareRadiogroup"
                        formControlName="receivesSocialWelfare"
                        name="receivesSocialWelfare"
                        [bigStFieldTracking]="{
                            metadata,
                            fieldName: receivesSocialWelfareFormRow.label!,
                            valid: !hasBeenSubmitted || form.controls.receivesSocialWelfare.valid
                        }" />
                    <ng-template #socialWelfareInfo>
                        {{ t('label.socialWelfareInfo') }}
                        <ul class="mt-2 ml-6 list-disc list-outside">
                            <li>{{ t('label.socialWelfareInfoBulletPoint1') }}</li>
                            <li>{{ t('label.socialWelfareInfoBulletPoint2') }}</li>
                            <li>{{ t('label.socialWelfareInfoBulletPoint3') }}</li>
                        </ul>
                    </ng-template>
                </big-ui-form-row>
                @if (form.controls.receivesSocialWelfare.value === false) {
                    <div class="mb-6">
                        <strong>{{ t('title.familyIncome') }}</strong>
                        <p>{{ t('title.familyIncomeSub') }}</p>
                        @for (name of specialFormGroups; track i; let i = $index) {
                            <ng-container [formGroupName]="name">
                                <big-ui-form-row
                                    #currencyFormRow
                                    [id]="name + 'Amount'"
                                    [label]="t('label.' + name)"
                                    [isRequired]="false"
                                    [errors]="form.controls[name].controls.amount.errors"
                                    [showError]="hasBeenSubmitted"
                                    [infoTextTemplate]="infoTemplates[name]"
                                    (infoLayerOpened)="trackStInfoIconEvent(currencyFormRow.label!)">
                                    <big-ui-currency-input
                                        [id]="name + 'Amount'"
                                        [attr.name]="name + 'Amount'"
                                        formControlName="amount"
                                        [isRequired]="false"
                                        (ngModelChange)="updateRequiredFieldsOnBlur(name)"
                                        [isValid]="form.controls[name].controls.amount.valid"
                                        [showValidation]="hasBeenSubmitted"
                                        [bigStFieldTracking]="{
                                            metadata,
                                            fieldName: currencyFormRow.label!,
                                            valid: !hasBeenSubmitted || form.controls[name].controls.amount.valid
                                        }" />
                                </big-ui-form-row>
                                <big-ui-form-row
                                    #periodFormRow
                                    [id]="name + 'Period'"
                                    [label]="t('label.period')"
                                    [isRequired]="isFieldRequired[name]"
                                    [isDisabled]="form.controls[name].controls.period.disabled"
                                    [errors]="form.controls[name].controls.period.errors"
                                    [showError]="hasBeenSubmitted">
                                    <big-ui-select
                                        [id]="name + 'Period'"
                                        [isValid]="form.controls[name].controls.period.valid"
                                        [options]="periodOptions"
                                        [showValidation]="hasBeenSubmitted"
                                        [isRequired]="isFieldRequired[name]"
                                        formControlName="period"
                                        [attr.name]="name + 'Period'"
                                        [bigStFieldTracking]="{
                                            metadata,
                                            fieldName: periodFormRow.label!,
                                            valid: !hasBeenSubmitted || form.controls[name].controls.period.valid
                                        }" />
                                </big-ui-form-row>
                            </ng-container>
                        }
                    </div>
                }
                <big-ui-form-row
                    #hasChronicIllnessFormRow
                    [id]="'hasChronicIllness'"
                    [label]="t('label.hasChronicIllness')"
                    [isRequired]="true"
                    [errors]="form.controls.hasChronicIllness.errors"
                    [showError]="hasBeenSubmitted"
                    [infoTextTemplate]="chronicIllnessInfo"
                    radiogroupId="hasChronicIllnessRadiogroup"
                    (infoLayerOpened)="trackStInfoIconEvent(hasChronicIllnessFormRow.label!)">
                    <big-ui-button-group
                        class="w-full"
                        buttonRowClasses="w-full md:w-6/12 lg:w-4/12"
                        [isValid]="form.controls.hasChronicIllness.valid"
                        [isTouched]="form.controls.hasChronicIllness.touched"
                        [options]="socialWelfareOptions"
                        radiogroupId="hasChronicIllnessRadiogroup"
                        [showValidation]="hasBeenSubmitted"
                        [isRequired]="true"
                        formControlName="hasChronicIllness"
                        name="hasChronicIllness"
                        [bigStFieldTracking]="{
                            metadata,
                            fieldName: hasChronicIllnessFormRow.label!,
                            valid: !hasBeenSubmitted || form.controls.hasChronicIllness.valid
                        }" />
                    <ng-template #chronicIllnessInfo>
                        {{ t('label.chronicIllnessInfo') }}
                    </ng-template>
                </big-ui-form-row>
                <big-ui-form-row
                    #livesInMarriedHouseholdFormRow
                    [id]="'livesInMarriedHousehold'"
                    [label]="t('label.livesInMarriedHousehold')"
                    [isRequired]="true"
                    [errors]="form.controls.livesInMarriedHousehold.errors"
                    [showError]="hasBeenSubmitted"
                    radiogroupId="livesInMarriedHouseholdRadioGroup">
                    <big-ui-button-group
                        class="w-full"
                        buttonRowClasses="w-full md:w-6/12 lg:w-4/12"
                        [isValid]="form.controls.livesInMarriedHousehold.valid"
                        [isTouched]="form.controls.livesInMarriedHousehold.touched"
                        [options]="socialWelfareOptions"
                        [showValidation]="hasBeenSubmitted"
                        [isRequired]="true"
                        formControlName="livesInMarriedHousehold"
                        radiogroupId="livesInMarriedHouseholdRadioGroup"
                        name="livesInMarriedHousehold"
                        [bigStFieldTracking]="{
                            metadata,
                            fieldName: livesInMarriedHouseholdFormRow.label!,
                            valid: !hasBeenSubmitted || form.controls.livesInMarriedHousehold.valid
                        }" />
                </big-ui-form-row>
                <big-ui-form-row
                    #numberOfChildrenFormRow
                    [id]="'numberOfChildren'"
                    [label]="t('label.numberOfChildren')"
                    [isRequired]="true"
                    [errors]="form.controls.numberOfChildren.errors"
                    [showError]="hasBeenSubmitted"
                    [infoTextTemplate]="numberOfChildrenInfo"
                    (infoLayerOpened)="trackStInfoIconEvent(numberOfChildrenFormRow.label!)">
                    <big-ui-select
                        [id]="'numberOfChildren'"
                        [isValid]="form.controls.numberOfChildren.valid"
                        [options]="numberOfChildrenOptions"
                        [disableTransloco]="true"
                        [showValidation]="hasBeenSubmitted"
                        [isRequired]="true"
                        formControlName="numberOfChildren"
                        name="numberOfChildren"
                        [bigStFieldTracking]="{
                            metadata,
                            fieldName: numberOfChildrenFormRow.label!,
                            valid: !hasBeenSubmitted || form.controls.numberOfChildren.valid
                        }" />
                    <ng-template #numberOfChildrenInfo>
                        {{ t('label.numberOfChildrenInfo') }}
                    </ng-template>
                </big-ui-form-row>
                <big-ui-button
                    [buttonStyle]="'secondary'"
                    [id]="'submit'"
                    class="block my-10 last:mb-0"
                    (clicked)="submit($event)">
                    {{ t('label.submit') }}
                </big-ui-button>
            </form>
        }
        @if (calculationResult) {
            <big-service-tool-co-payment-calculator-result
                [bigSharedScrollMargin]
                [calculationResult]="calculationResult"
                (backClicked)="onBackClick()" />
        }
        @if (hasDataLoadingError) {
            <big-ui-alert
                [title]="t('error.title')"
                [classList]="'flex text-center border-2 rounded-md flex-column !p-4'"
                type="danger">
                {{ t('error.unavailable', {number: errorPhoneNumber.title }) }}
            </big-ui-alert>
        }

        <ng-template #familyIncomeInfo>
            {{ t('label.familyIncomeInfo') }}
            <ul class="mt-2 ml-6 list-disc list-outside">
                <li>{{ t('label.familyIncomeInfoBulletPoint1') }}</li>
                <li>{{ t('label.familyIncomeInfoBulletPoint2') }}</li>
                <li>
                    {{ t('label.familyIncomeInfoBulletPoint3') }}
                    <ul class="ml-6 list-outside list-circle">
                        <li>{{ t('label.familyIncomeInfoBulletPoint3.1') }}</li>
                        <li>{{ t('label.familyIncomeInfoBulletPoint3.2') }}</li>
                        <li>{{ t('label.familyIncomeInfoBulletPoint3.3') }}</li>
                        <li>{{ t('label.familyIncomeInfoBulletPoint3.4') }}</li>
                    </ul>
                </li>
                <li>{{ t('label.familyIncomeInfoBulletPoint4') }}</li>
                <li>
                    {{ t('label.familyIncomeInfoBulletPoint5') }}
                </li>
                <li>{{ t('label.familyIncomeInfoBulletPoint6') }}</li>
            </ul>
        </ng-template>
    </ng-container>
}
