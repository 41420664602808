import { Injectable } from '@angular/core';
import { type CoPaymentCalculationParametersModel } from '../models/co-payment-calculation-parameters.model';
import { type CoPaymentCalculationResultModel } from '../models/co-payment-calculation-result.model';
import { type CoPaymentCalculationTaxonomiesByYear } from '../models/co-payment-calculation-taxonomies-by-year.model';
import { type Periods } from '../models/period.model';

@Injectable({
    providedIn: 'root',
})
export class CoPaymentCalculatorService {
    private readonly monthsPerYear = 12;

    public calculateCoPayment(
        model: CoPaymentCalculationParametersModel,
        taxonomiesByYear: CoPaymentCalculationTaxonomiesByYear,
    ): CoPaymentCalculationResultModel {
        const {
            year,
            receivesSocialWelfare,
            familyIncome,
            familyIncomePeriod,
            allowances,
            allowancesPeriod,
            otherIncome,
            otherIncomePeriod,
            hasChronicIllness,
            livesInMarriedHousehold,
            numberOfChildren,
        } = model;

        const yearlyIncome = receivesSocialWelfare
            ? this.getYearlyIncomeForUnemployment(year, taxonomiesByYear)
            : this.getYearlyIncome(familyIncome, familyIncomePeriod, allowances, allowancesPeriod, otherIncome, otherIncomePeriod);

        const spouseExemption = this.getEffectiveSpouseExemption(year, receivesSocialWelfare, livesInMarriedHousehold, taxonomiesByYear);

        const childrenExemption = this.getEffectiveChildrenExemption(year, numberOfChildren, receivesSocialWelfare, taxonomiesByYear);

        const percentDivisor = 100;
        const coPaymentLimitFactor = this.getCoPaymentLimitFactor(hasChronicIllness) / percentDivisor;

        let calculationBasis = yearlyIncome - spouseExemption - childrenExemption;
        let coPayment = calculationBasis * coPaymentLimitFactor;

        if (coPayment < 0) {
            calculationBasis = 0;
            coPayment = 0;
        }

        return {
            year,
            yearlyIncome,
            coPayment,
            calculationBasis,
            spouseExemption,
            childrenExemption,
            coPaymentLimitFactor,
            numberOfChildren,
        };
    }

    private getIncomeBasis(income?: number, period?: Periods): number {
        if (income && period) {
            return period === 'month' ? income * this.monthsPerYear : income;
        }

        return 0;
    }

    private getYearlyIncome(
        familyIncome?: number,
        familyIncomePeriod?: Periods,
        allowances?: number,
        allowancesPeriod?: Periods,
        otherIncome?: number,
        otherIncomePeriod?: Periods,
    ): number {
        let result = 0;
        result += this.getIncomeBasis(familyIncome, familyIncomePeriod);
        result += this.getIncomeBasis(allowances, allowancesPeriod);
        result += this.getIncomeBasis(otherIncome, otherIncomePeriod);

        return result;
    }

    private getYearlyIncomeForUnemployment(year: number, taxonomiesByYear: CoPaymentCalculationTaxonomiesByYear): number {
        let baseAmount;
        let i = year;

        while (!baseAmount && i >= 0) {
            const taxonomies = taxonomiesByYear.get(i);

            if (taxonomies?.unemploymentBenefitsBaseAmount) {
                baseAmount = taxonomies.unemploymentBenefitsBaseAmount;
            }

            i -= 1;
        }

        if (baseAmount === undefined) {
            throw new Error('Could not find "unemploymentBenefitsBaseAmount"');
        }

        return this.monthsPerYear * baseAmount;
    }

    private getEffectiveSpouseExemption(
        year: number,
        receivesSocialWelfare: boolean,
        livesInMarriedHousehold: boolean,
        taxonomiesByYear: CoPaymentCalculationTaxonomiesByYear,
    ): number {
        if (!(!receivesSocialWelfare && livesInMarriedHousehold)) {
            return 0;
        }

        let exemption;
        let i = year;

        while (!exemption && i >= 0) {
            const taxonomies = taxonomiesByYear.get(i);

            if (taxonomies?.spouseExemption) {
                exemption = taxonomies.spouseExemption;
            }

            i -= 1;
        }

        if (exemption === undefined) {
            throw new Error('Could not find "spouseExemption"');
        }

        return exemption;
    }

    private getEffectiveChildrenExemption(
        year: number,
        numberOfChildren: number | string,
        receivesSocialWelfare: boolean,
        taxonomiesByYear: CoPaymentCalculationTaxonomiesByYear,
    ): number {
        if (!(!receivesSocialWelfare && typeof numberOfChildren === 'number' && numberOfChildren > 0)) {
            return 0;
        }

        let result = 0;
        let exemption;
        let i = year;

        while (!exemption && i >= 0) {
            const taxonomies = taxonomiesByYear.get(i);

            if (taxonomies?.childrenExemption) {
                exemption = taxonomies.childrenExemption;
            }

            i -= 1;
        }

        if (exemption === undefined) {
            throw new Error('Could not find "childrenExemption"');
        }

        result = exemption * numberOfChildren;

        return result;
    }

    private getCoPaymentLimitFactor(hasChronicIllness: boolean): number {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers,sonarjs/no-selector-parameter
        return hasChronicIllness ? 1 : 2;
    }
}
